import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css

import {Pagination,Input,Radio, Carousel,CarouselItem, Cascader, Menu, Submenu, MenuItem, MenuItemGroup, Table,Dropdown,TableColumn,Popover, Select, Option,Dialog,Upload,Button,DatePicker,TimeSelect,TimePicker,Switch,Loading, MessageBox,Slider, Message, Notification} from 'element-ui'

Vue.use(Pagination)
    .use(Input)
    .use(Radio)
    .use(Carousel)
    .use(CarouselItem)
    .use(Cascader)
    .use(Menu)
    .use(Submenu)
    .use(MenuItem)
    .use(MenuItemGroup)
    .use(Table)
    .use(Dropdown)
    .use(TableColumn)
    .use(Popover)
    .use(Select)
    .use(Option)
    .use(Dialog)
    .use(Upload)
    .use(Button)
    .use(DatePicker)
    .use(TimeSelect)
    .use(TimePicker)
    .use(Switch)
    .use(Slider)
    .use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
