import Vue from "vue";
import Vuex from "vuex";

import defaultState from "./state/state"
import mutations from "./mutations/mutations"
import actions from "./actions/actions"

Vue.use(Vuex);
export default ()=>{
  const store = new Vuex.Store({
    state: defaultState,
    mutations,
    actions,
    modules: {},
  });
  let newState;
  let newMutations;
  // store 的热更替
  if (module.hot) {
    module.hot.accept([
      './state/state',
      './mutations/mutations'
    ], () => {
      newState = require("./state/state").default;
      newMutations = require("./mutations/mutations").default;

      store.hotUpdate({
        state: newState,
        mutations: newMutations
      })
    })
  }
return store
}
