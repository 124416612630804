<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  export default {
    watch: {
      '$route': function(){
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
    }
  }
</script>
<style lang="scss">
  @import './assets/css/_normalize.scss';
  #app{
    min-height:100%;
    height:100%;
    /*overflow: hidden;*/
    font-family: "PingFang SC", "Helvetica", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "STHeitiSC-Light", "Helvetica-Light", arial, sans-serif, "Droid Sans Fallback";
  }
</style>
