
export default [
  {
  path: "/",
    name: "Index",
    redirect: "/index"
  },
  {
  path: "/index",
    name: "Index",
    component: () => import("@/views/index"),
    children: [
      {
        path: "",
        redirect: "home"
      },
      {
        path: "home",
        component: () => import("@/views/home-page/home"),
      },
      {
        path: "product",
        component: () => import("@/views/navigation-page/product-page"),
      },
      {
        path: "function",
        component: () => import("@/views/navigation-page/function-page"),
      },
      {
        path: "client",
        component: () => import("@/views/navigation-page/client-page"),
      },
      {
        path: "cooperation",
        component: () => import("@/views/navigation-page/cooperation-page"),
      },
    ]
  },
];
